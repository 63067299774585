import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ShopGrid from "./Screens/ShopGrid";
import ShopList from "./Screens/ShopList";
import ShopCart from "./Screens/ShopCart";
import ShopCheckout from "./Screens/ShopCheckout";
import LogIn from "./Screens/AuthPage/LogIn";
import SignUp from "./Screens/AuthPage/SignUp";
import ForgotPassword from "./Screens/AuthPage/ForgotPassword";
import LockScreen from "./Screens/AuthPage/LockScreen";
import UserAccount from "./Screens/MyAccount/UserAccount";
import Billing from "./Screens/MyAccount/Billing";
import Invoice from "./Screens/MyAccount/Invoice";
import Settings from "./Screens/MyAccount/Settings";
import About from "./Screens/About";
import HomeScreen1 from "./Screens/Home/HomeScreen1";
import Navbar from "./Screens/Navbar/Navbar";
import { ProductsProvider } from "./Context/ProductsContext";
import { OrderProvider } from "./Context/OrderContext";
import { MetaDataContextProvider } from "./Context/MetaDataContext";
import CategoryScreen from "./Screens/CategoryScreen";
import { AuthContextProvider } from "./Context/AuthContext";
import ShopDetail from "./Screens/ShopDetail";
import ProductScreen from "./Screens/ProductScreen";
import { CartProvider } from "./Context/CartContext";
import Cart from "./Screens/Cart";
import CheckoutScreen from "./Screens/CheckoutScreen";
import Footer from "./Screens/Footer/Footer";
import MyAccount from "./Screens/MyAccountScreen";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import OrderDetailScreen from "./Screens/OrderScreen";
import ShopPage from "./ShopPage";
import AdminDashboard from "./Dashboard";
// import ShopPage from "./ShopPage1";

const App: React.FC = () => {
  return (
    <div>
      <AuthContextProvider>
        <ProductsProvider>
          <CartProvider>
            <OrderProvider>
              <MetaDataContextProvider>
                <Navigation />
              </MetaDataContextProvider>
            </OrderProvider>
          </CartProvider>
        </ProductsProvider>
      </AuthContextProvider>
    </div>
  );
};

const Navigation: React.FC = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <ToastContainer />
      <Switch>
        <Route exact path="/homeOne" component={HomeScreen1} />
        <Route exact path="/about" component={About} />
        <Route exact path="/" component={HomeScreen1} />
        <Route exact path="/shop" component={ShopPage} />
        <Route path="/admin" component={AdminDashboard} />
        <Route exact path="/shopList" component={ShopList} />
        <Route exact path="/shopCart" component={ShopCart} />
        <Route exact path="/shopCheckout" component={ShopCheckout} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/lockScreen" component={LockScreen} />
        <Route exact path="/userAccount" component={UserAccount} />
        <Route exact path="/billingInfo" component={Billing} />
        <Route exact path="/invoice" component={Invoice} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/shopDetail" component={ShopDetail} />
        <Route exact path="/category/:catId" component={CategoryScreen} />
        <Route exact path="/subcategory/:subcatId" component={CategoryScreen} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/checkout" component={CheckoutScreen} />
        <Route exact path="/my-account" component={MyAccount} />

        <Route exact path="/product/:productId" component={ProductScreen} />
        <Route exact path="/order/:orderId" component={OrderDetailScreen} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
