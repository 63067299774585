import { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  onSnapshot,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid"; // npm install uuid
import ReactGA from "react-ga"; // npm install react-ga
import { UAParser } from "ua-parser-js"; // npm install ua-parser-js
import { db } from "./firebase/config";
import { motion } from "framer-motion"; // npm install framer-motion

// Initialize Google Analytics
ReactGA.initialize("G-JDN1Z830VP");

// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
};

const bounce = {
  hidden: { scale: 0.8, opacity: 0 },
  visible: {
    scale: 1,
    opacity: 1,
    transition: { type: "spring", stiffness: 300, damping: 20 },
  },
};

const hoverEffect = {
  scale: 1.05,
  transition: { duration: 0.3 },
};

function Shop() {
  const [scanCount, setScanCount] = useState(0);

  useEffect(() => {
    let userId = localStorage.getItem("userId");
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem("userId", userId);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source") || "unknown";

    const parser = new UAParser();
    const uaResult = parser.getResult();
    const deviceInfo = {
      browser: `${uaResult.browser.name} ${uaResult.browser.version}`,
      os: `${uaResult.os.name} ${uaResult.os.version}`,
      device: uaResult.device.model || uaResult.device.type || "Unknown",
    };

    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.set({
      dimension1: deviceInfo.browser,
      dimension2: deviceInfo.os,
      dimension3: deviceInfo.device,
    });

    const trackScan = async () => {
      try {
        const scanData = {
          page: "/shop",
          timestamp: serverTimestamp(),
          userAgent: navigator.userAgent,
          userId: userId,
          source: source,
          browser: deviceInfo.browser,
          os: deviceInfo.os,
          device: deviceInfo.device,
        };
        await addDoc(collection(db, "qr-scans"), scanData);
      } catch (error) {
        console.error("Error tracking scan:", error);
      }
    };
    trackScan();

    const q = query(collection(db, "qr-scans"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setScanCount(snapshot.size);
    });

    return () => unsubscribe();
  }, []);

  const trackInteraction = (action) => {
    ReactGA.event({
      category: "User Interaction",
      action: action,
      label: "Shop Page",
    });
  };

  const handleFeedbackSubmit = (e) => {
    e.preventDefault();
    const rating = e.target.rating.value;
    trackInteraction("Submitted Feedback");
    addDoc(collection(db, "feedback"), {
      rating: parseInt(rating),
      timestamp: serverTimestamp(),
    });
    e.target.reset();
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-100 via-pink-100 to-blue-100 flex flex-col items-center p-6 px-3">
      {/* Logo */}
      <motion.div
        className="mb-8"
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
      >
        <img
          src={require("./assets/images/sj.webp")}
          alt="Shreejee Bliss Logo"
          className="w-40 h-40 rounded-full border-4 border-white shadow-md"
        />
      </motion.div>

      {/* Shop Content */}
      <motion.div
        className="w-full max-w-5xl bg-white p-8 rounded-2xl shadow-xl"
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
      >
        <h1 className="text-2xl text-center mb-2">Welcome to</h1>
        <h2 className="text-3xl font-bold text-center mb-6">
          <span className="font-bold text-purple-600">Shreejee Bliss</span>
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Visit us at:{" "}
          <span className="font-semibold text-purple-600">
            Main Bazaar, Palwal Near Manchanda Clinic
          </span>
        </p>

        {/* Social Media Links */}
        <motion.div
          className="flex justify-center gap-6 mb-8 items-center"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <motion.a
            href="https://instagram.com/shreejee_bliss"
            target="_blank"
            rel="noopener noreferrer"
            animate={{ scale: [1, 1.1, 1], rotate: [0, 5, 0] }}
            transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
            whileHover={{ scale: 1.2, rotate: 10 }}
            onClick={() => trackInteraction("Clicked Instagram")}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/195px-Instagram_logo_2022.svg.png"
              alt="Instagram"
              className="h-12"
            />
          </motion.a>
          <motion.a
            href="https://instagram.com/shreejee_bliss"
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple-600 font-bold"
            animate={{ color: ["#9333ea", "#ec4899", "#9333ea"] }}
            transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
            whileHover={{ scale: 1.05, color: "#7e22ce" }}
            onClick={() => trackInteraction("Clicked Instagram")}
          >
            Follow on Instagram
          </motion.a>
        </motion.div>

        {/* Contact Options with Clickable Links */}
        <motion.div
          className="flex flex-col md:flex-row justify-center gap-6 mb-8"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <div className="text-center">
            <motion.a
              href="https://wa.me/+919306187406"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 mb-2 block hover:text-purple-600"
              onClick={() => trackInteraction("Clicked WhatsApp Number")}
            >
              +91 93061 87406
            </motion.a>
            <motion.a
              href="https://wa.me/+919306187406"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gradient-to-r from-green-400 to-green-600 text-white px-6 py-3 rounded-full shadow-lg hover:from-green-500 hover:to-green-700"
              onClick={() => trackInteraction("Clicked WhatsApp")}
              whileHover={hoverEffect}
              whileTap={{ scale: 0.95 }}
            >
              Click to WhatsApp
            </motion.a>
          </div>
          <div className="text-center">
            <motion.a
              href="tel:+919306187406"
              className="text-gray-600 mb-2 block hover:text-purple-600"
              onClick={() => trackInteraction("Clicked Call Number")}
            >
              +91 93061 87406
            </motion.a>
            <motion.a
              href="tel:+919306187406"
              className="bg-gradient-to-r from-blue-400 to-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:from-blue-500 hover:to-blue-700"
              onClick={() => trackInteraction("Clicked Call")}
              whileHover={hoverEffect}
              whileTap={{ scale: 0.95 }}
            >
              Click to Call
            </motion.a>
          </div>
        </motion.div>

        {/* Google Maps */}
        <motion.div
          className="mb-8"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <h2 className="text-2xl font-semibold text-gray-700 mb-3">Find Us</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d219.8797299957967!2d77.32554055894961!3d28.144184236536514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cd3b1411e9379%3A0x7a76e1cad6489aa4!2s564%2C%20Khail%20Mohalla%2C%20Palwal%2C%20Haryana%20121102!5e0!3m2!1sen!2sin!4v1742450798486!5m2!1sen!2sin"
            width="100%"
            height="350"
            style={{ border: 0, borderRadius: "12px" }}
            allowFullScreen=""
            loading="lazy"
            title="Shop Location"
            className="shadow-md"
          ></iframe>
          <motion.a
            href="https://maps.app.goo.gl/Tp2JHKQWDQj2f9n46"
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple-600 underline mt-3 inline-block hover:text-purple-800"
            onClick={() => trackInteraction("Clicked Map Link")}
            whileHover={hoverEffect}
          >
            Open in Google Maps
          </motion.a>
        </motion.div>

        {/* Store Hours */}
        <motion.div
          className="mb-8"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <h2 className="text-2xl font-semibold text-gray-700 mb-3">
            Store Hours
          </h2>
          <p className="text-gray-600">Monday - Saturday: 10 AM - 8 PM</p>
          <p className="text-gray-600">Sunday: 10 AM - 6 PM</p>
        </motion.div>

        {/* Promotions */}
        <motion.div
          className="mb-8 bg-gradient-to-r from-yellow-200 to-yellow-300 p-6 rounded-xl shadow-md"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <h2 className="text-2xl font-semibold text-yellow-800 mb-2">
            Special Offer
          </h2>
          <p className="text-yellow-900">
            Get an Assured Gift on Every Purchase!
          </p>
        </motion.div>

        {/* Scan Counter */}
        <motion.p
          className="text-center text-gray-600 mb-6 font-bold text-lg"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          We Enabled Live Tracking on This Page
        </motion.p>
        <motion.p
          className="text-center text-2xl font-semibold text-purple-600 mb-8"
          variants={bounce}
          initial="hidden"
          animate="visible"
        >
          {scanCount}+ Customers Have Visited Us!
        </motion.p>

        {/* Feedback Form */}
        <motion.div variants={fadeInUp} initial="hidden" animate="visible">
          <h2 className="text-2xl font-semibold text-gray-700 mb-3">
            Rate Your Experience
          </h2>
          <form
            onSubmit={handleFeedbackSubmit}
            className="flex gap-4 justify-center"
          >
            <input
              type="number"
              name="rating"
              min="1"
              max="5"
              placeholder="1-5"
              className="w-24 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
              required
            />
            <motion.button
              type="submit"
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-3 rounded-lg shadow-lg hover:from-purple-600 hover:to-pink-600"
              whileHover={hoverEffect}
              whileTap={{ scale: 0.95 }}
            >
              Submit Rating
            </motion.button>
          </form>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Shop;
