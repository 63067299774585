import ShopList from "../../Reusable/List/ShopList";
import { ProductType } from "../../types";

const Category: React.FC<{ filteredProducts: ProductType[] }> = ({
  filteredProducts,
}) => {
  return (
    <>
      <div className="container font-bold mt-2 mx-2">
        Showing {filteredProducts.length} items
      </div>
      <ShopList filteredProducts={filteredProducts} />;
    </>
  );
};

export default Category;
