import { Link } from "react-router-dom";
import { FaInstagram, FaFacebook, FaEnvelope } from "react-icons/fa";
import {
  MdTruckCheck,
  MdArchive,
  MdAttachMoney,
  MdShield,
} from "react-icons/md";
import logo from "../Navbar/logo.svg";

const Footer = () => {
  return (
    <footer className="footer bg-slate-950 relative text-gray-200 dark:text-gray-200">
      <div className="container relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                <div className="lg:col-span-3 md:col-span-12">
                  <Link to="/" className="text-[22px] focus:outline-none">
                    <img src={logo} alt="Carryall Bliss Logo" />
                  </Link>
                  <p className="mt-6 text-gray-300">
                    Discover your bliss with our collection of handbags,
                    accessories, and jewelry that blend style with convenience.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="inline">
                      <a
                        href="https://www.instagram.com/carryallbliss"
                        target="_blank"
                        className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                        rel="noreferrer"
                      >
                        <FaInstagram
                          className="h-4 w-4 align-middle"
                          title="Instagram"
                        />
                      </a>
                    </li>
                    <li className="inline ml-2">
                      <a
                        href="https://www.facebook.com/carryallbliss"
                        target="_blank"
                        className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                        rel="noreferrer"
                      >
                        <FaFacebook
                          className="h-4 w-4 align-middle"
                          title="Facebook"
                        />
                      </a>
                    </li>
                    <li className="inline ml-2">
                      <a
                        href="mailto:support@carryallbliss.com"
                        className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                      >
                        <FaEnvelope
                          className="h-4 w-4 align-middle"
                          title="Email"
                        />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="lg:col-span-6 md:col-span-12">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Our Collections
                  </h5>
                  <div className="grid md:grid-cols-12 grid-cols-1">
                    <div className="md:col-span-4">
                      <ul className="list-none footer-list mt-6">
                        <li>
                          <Link
                            to="/category/Bags"
                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            Bags
                          </Link>
                        </li>
                        <li className="ml-4 mt-[10px]">
                          <a
                            href="https://carryallbliss.com/subcategory/Bridal%20Bags"
                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            Bridal Bags
                          </a>
                        </li>
                        {/* Other links remain the same */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <div className="py-[30px] px-0 border-t border-slate-800">
            <div className="grid lg:grid-cols-4 md:grid-cols-2">
              <div className="flex items-center lg:justify-center">
                <MdArchive className="align-middle text-lg mb-0 me-2" />
                <h6 className="mb-0 font-medium">Free delivery</h6>
              </div>
              <div className="flex items-center lg:justify-center">
                <MdArchive className="align-middle text-lg mb-0 me-2" />
                <h6 className="mb-0 font-medium">Non-contact shipping</h6>
              </div>
              <div className="flex items-center lg:justify-center">
                <MdAttachMoney className="align-middle text-lg mb-0 me-2" />
                <h6 className="mb-0 font-medium">Money-back guarantee</h6>
              </div>
              <div className="flex items-center lg:justify-center">
                <MdShield className="align-middle text-lg mb-0 me-2" />
                <h6 className="mb-0 font-medium">Secure payments</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="py-[30px] px-0 border-t border-slate-800">
          <div className="container relative text-center">
            <div className="grid md:grid-cols-2 items-center">
              <div className="md:text-start text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} Carryall Bliss.
                </p>
              </div>
              <ul className="list-none md:text-end text-center mt-6 md:mt-0">
                {/* Payment icons (add respective icons using react-icons or images if preferred) */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
