import { useEffect, useState } from "react";
import { useProductsContext } from "../Context/ProductsContext";
import { ProductType } from "../types";
import Category from "../Container/Category/CategoryScreen";
import Breadcrumbs from "../Reusable/BreadCrums/BreadCrums";
import { useMetaDataContext } from "../Context/MetaDataContext";
import { Link } from "react-router-dom";

const CategoryScreen: React.FC<{ match: any }> = ({ match }) => {
  const { products, fetchProducts } = useProductsContext();
  const [filteredProducts, setFilteredProducts] = useState<ProductType[]>([]);
  const [breadcrumbs, setBreadCrumbs] = useState<any[]>([]);

  console.log(products);
  const { catId, subcatId } = match.params;

  useEffect(() => {
    if (products) {
      if (catId) {
        let searchId = decodeURI(catId).toLocaleLowerCase();
        const filtered: any = products.filter(
          (product: any) => product.mainCategory.toLowerCase() === searchId
        );
        let breads = [
          { label: "home", path: "/" },
          { label: searchId, path: "" },
        ];
        setBreadCrumbs(breads);
        setFilteredProducts(filtered);
      }
      if (subcatId) {
        let searchId = decodeURI(subcatId).toLocaleLowerCase();
        console.log(searchId);
        const filtered = products.filter(
          (product: any) => product.subCategory.toLowerCase() === searchId
        );
        let breads = [
          { label: "home", path: "/" },
          {
            label: filtered[0]?.mainCategory || "category",
            path: `/category/${filtered[0]?.mainCategory || "/"}`,
          },
          { label: searchId, path: "/" },
        ];
        setBreadCrumbs(breads);
        setFilteredProducts(filtered);
      }
    }
  }, [catId, products, subcatId]);

  // Here you can use carId to fetch data related to the category or perform any other operations
  return (
    <div>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {catId && <SubCategoriesBubble catId={catId} />}
      {filteredProducts?.length && (
        <Category filteredProducts={filteredProducts} />
      )}
      {/* {products ? (
        <ul>
          {products.map((product) => (
            <li key={product.id}>{product.productName}</li>
          ))}
        </ul>
      ) : (
        <p>Loading...</p>
      )} */}
    </div>
  );
};

function SubCategoriesBubble(props: any) {
  const { catId } = props;
  const { metaData }: any = useMetaDataContext();
  console.warn(metaData);
  let categories = [];

  const categoriesObj = metaData.find((i: any) => i.id === "categories");
  if (categoriesObj) {
    categories = categoriesObj.categories;
  }
  console.warn(categories);
  const foundCategory = categories.find((c: any) => c.name === catId);
  const subCategories = foundCategory.subCategories || [];
  return (
    <>
      <div className=" container grid lg:grid-cols-7 md:grid-cols-5 sm:grid-cols-5 grid-cols-3 pt-6 gap-6">
        {subCategories.map((sc: any) => {
          return (
            <Link
              className="text-center hover:text-orange-500 cursor-pointer"
              style={{ width: "100%" }}
              to={`/subcategory/${sc.name}`}
            >
              <div className="aspect-square">
                <img
                  src={
                    sc.imgUrl ||
                    "https://shreethemes.in/cartzio/layouts/assets/images/categories/mens-ware.jpg"
                  }
                  className="rounded-full shadow-2xl dark:shadow-gray-800 object-contain"
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>

              <span className="text-xl font-medium mt-3 block">{sc.name}</span>
            </Link>
          );
        })}
      </div>
    </>
  );
}

export default CategoryScreen;
