import { useEffect, useState } from "react";

import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { db } from "./firebase/config";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AdminDashboard() {
  const [scans, setScans] = useState([]);
  const [filterSource, setFilterSource] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch data from Firestore in real-time
  useEffect(() => {
    const q = query(collection(db, "qr-scans"), orderBy("timestamp", "desc"));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const scanData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setScans(scanData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching scans:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  // Calculate summary stats
  const totalScans = scans.length;
  const uniqueUsers = new Set(scans.map((scan) => scan.userId)).size;
  const avgSessionDuration =
    scans
      .filter((scan) => scan.sessionDuration)
      .reduce((acc, scan) => acc + scan.sessionDuration, 0) /
    (scans.filter((scan) => scan.sessionDuration).length || 1);

  // Filter scans by source and date
  const filteredScans = scans.filter((scan) => {
    const scanDate = scan.timestamp?.toDate();
    return (
      (filterSource === "all" || scan.source === filterSource) &&
      (!startDate || scanDate >= startDate) &&
      (!endDate || scanDate <= endDate)
    );
  });

  // Prepare chart data (daily scan counts)
  const getChartData = () => {
    const dateCounts = {};
    filteredScans.forEach((scan) => {
      const date = scan.timestamp?.toDate().toLocaleDateString();
      dateCounts[date] = (dateCounts[date] || 0) + 1;
    });

    const labels = Object.keys(dateCounts).sort();
    const data = labels.map((label) => dateCounts[label]);

    return {
      labels,
      datasets: [
        {
          label: "QR Scans",
          data,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          tension: 0.1,
        },
      ],
    };
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "QR Scans Over Time" },
    },
    scales: {
      x: { title: { display: true, text: "Date" } },
      y: {
        title: { display: true, text: "Number of Scans" },
        beginAtZero: true,
      },
    },
  };

  // Get unique sources for filter dropdown
  const sources = ["all", ...new Set(scans.map((scan) => scan.source))];

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">
        Shreejee Bliss QR Scan Dashboard
      </h1>

      {/* Summary Stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="p-4 bg-gray-100 rounded">
          <h2 className="text-lg">Total Scans</h2>
          <p className="text-2xl">{totalScans}</p>
        </div>
        <div className="p-4 bg-gray-100 rounded">
          <h2 className="text-lg">Unique Users</h2>
          <p className="text-2xl">{uniqueUsers}</p>
        </div>
        <div className="p-4 bg-gray-100 rounded">
          <h2 className="text-lg">Avg Session (s)</h2>
          <p className="text-2xl">{avgSessionDuration.toFixed(1)}</p>
        </div>
      </div>

      {/* Filters */}
      <div className="mb-6 flex flex-col md:flex-row gap-4">
        <div>
          <label className="mr-2">Filter by Source:</label>
          <select
            value={filterSource}
            onChange={(e) => setFilterSource(e.target.value)}
            className="p-2 border rounded"
          >
            {sources.map((source) => (
              <option key={source} value={source}>
                {source}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="mr-2">Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="p-2 border rounded"
            placeholderText="Select start date"
          />
        </div>
        <div>
          <label className="mr-2">End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="p-2 border rounded"
            placeholderText="Select end date"
          />
        </div>
      </div>

      {/* Chart */}
      <div className="mb-6 bg-white p-4 rounded-lg shadow-md">
        <Line data={getChartData()} options={chartOptions} />
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2">Timestamp</th>
              <th className="border p-2">User ID</th>
              <th className="border p-2">Source</th>
              <th className="border p-2">Location</th>
              <th className="border p-2">Session (s)</th>
              <th className="border p-2">Browser</th>
              <th className="border p-2">OS</th>
              <th className="border p-2">Device</th>
            </tr>
          </thead>
          <tbody>
            {filteredScans.map((scan) => (
              <tr key={scan.id} className="hover:bg-gray-50">
                <td className="border p-2">
                  {scan.timestamp?.toDate().toLocaleString() || "N/A"}
                </td>
                <td className="border p-2">{scan.userId.slice(0, 8)}...</td>
                <td className="border p-2">{scan.source}</td>
                <td className="border p-2">
                  {scan.location
                    ? `${scan.location.latitude}, ${scan.location.longitude}`
                    : "N/A"}
                </td>
                <td className="border p-2">
                  {scan.sessionDuration?.toFixed(1) || "N/A"}
                </td>
                <td className="border p-2">{scan.browser || "N/A"}</td>
                <td className="border p-2">{scan.os || "N/A"}</td>
                <td className="border p-2">{scan.device || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminDashboard;
